.cond-header{
    background-image: linear-gradient(273deg, rgba($colorSecondary, .9) 48%, rgba($colorPrimary, .9) 65%, rgba($colorSecondary, .9) 94%);
}

.hr-condo{
    margin-top: 1px;
    width: 30%;
    padding-top: 0.8rem;
}

.brd-rig{
    border-right: solid 2px $gray4;
}

@media all and (min-width:320px) and (max-width: 1023px){
    .brd-rig{
        border-right: none;
    }
}