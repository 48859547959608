.rur_urb_mobile{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 1rem 0;
	background-color: white;

	& a{
		margin: 0 1rem;

		& p{
			background-color: $colorPrimary;
			padding: .3rem 2rem;
			font-size: .9rem;
			color: white;
			font-weight: 400;
			text-align: center;
			border-radius: .1rem;
			box-shadow: 0 0 15px 0 rgba(black, .2);
			transition: all .2s ease-in-out;

			& span{
				font-size: 1.5rem;
			}

			&:hover{
				background-color: darken($colorPrimary, 5);
			}
		}
	}

	& .btn_agro{
		margin: 0 !important;
		& a{
			& p{
				background-color: #00af53;
				padding: .9rem 2rem;
				font-size: 1.2rem;
				color: white;
				font-weight: 400;
				text-align: center;
				border-radius: .1rem;
				box-shadow: 0 0 15px 0 rgba(black, .2);
				transition: all .2s ease-in-out;
				
				
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}
}

.botao_buscar_int{
	padding: .58rem 0;
	margin-top: .19rem;
	background-color: $colorPrimary;
	border: solid 1px $colorPrimary;
	color: white;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.botao_limpar_int{
	padding: .58rem 0;
	margin-top: .19rem;
	background-color: $colorSecondary;
	border: solid 1px $colorSecondary;
	color: white;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	&:hover{
		background-color: darken($colorSecondary, 5);
	}
}

#ux_to_top{
	position: relative;
	top: -3px;
	left: -160%;
	z-index: 999999;
}

.text_nav2{
	font-size: 3rem;
	line-height: 2.5rem;
	color: black;
}

.bganuncie{
	background-color: $gray5;
	height: 30rem;
	filter: brightness(100%);
	position: relative;
	border-right: solid 1px $colorPrimary;
	&:hover{
		filter: brightness(103%);
	}
}

.bgencomende{
	background-color: $gray5;
	filter: brightness(100%);
	height: 30rem;
	position: relative;
	&:hover{
		filter: brightness(103%);
	}
}

.textanuncie{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center !important;
	& h3{
		color: $colorSecondary;
		font-size: 2.2rem;
		font-weight: 600
	}

	& p{
		font-size: 1.2rem;
		color: $colorSecondary;
		line-height: 1.5em;
		font-weight: 500;
		text-align: center !important;
	}
	.buttonanuncie{
		border-radius: .2rem;
		text-transform: initial !important;
		font-size: 1.1rem;
		font-weight: 800
	}
}

.box_header{
	//@include box-shadow('0 0 20px 5px rgba(0,0,0,.1)');
	z-index: 99;
	position: relative;
}

.sessao_sobre_imob{
	background-color: $colorSecondary;
	display: table;
	width: 100%;

	& .descricao_sobre{
		width: 100%;
		& img{
			margin: auto;
		}
		.text_nav2{
			font-size: 1.5rem;
			line-height: 2.5rem;
			color: white;
			font-weight: 600;
		}
		.text_nav3{
			& p{
				font-size: 1.2rem;
				line-height: 1.8rem;
				padding-bottom: 1rem;
				color: white;
				text-align: center;
			}
			& .p2{
				text-align: center;
				font-size: .8rem;
				color: white;
				font-weight: 600;
			}
			& a{
				cursor: pointer;
				color: white;
				font-weight: 600;
				text-align: center;
				vertical-align: middle;
				border: solid 1px rgba(white, .3);
				box-shadow: 0 0 20px 0 rgba($colorPrimary, .4);
				background-color: $colorPrimary;
				padding: .5rem 1rem;
				border-radius: 1rem;
				&:hover{
					background-color: darken($colorPrimary, 3);
				}
			}
		}
	}
}

@media all and (max-width: 1023px){

	.textanuncie{
		position: initial;
		transform: none;
	}

	.bganuncie, .bgencomende{
		height: 27rem;
	}
}

.input-novidades:-webkit-autofill,
.input-novidades:-webkit-autofill:hover, 
.input-novidades:-webkit-autofill:focus, 
.input-novidades:-webkit-autofill:active  {
    -webkit-box-shadow:0 0 0 50px rgba(0,0,0,0) inset !important;
    -webkit-text-fill-color: white !important;
}

@media all and (min-width: 320px) and (max-width: 1023px){
	.max-sz-con{
		max-width: 40rem !important;
		padding-left: 2rem !important;
		padding-right: 1rem !important;
	}
}

@media all and (max-width: 1023px){
	.botao_buscar_int{
		padding: .58rem 0;
		margin-top: .19rem;
		background-color: $colorPrimary;
		border: solid 1px $colorPrimary;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		cursor: pointer;
		&:hover{
			background-color: darken($colorPrimary, 5);
		}
	}
	
	.botao_limpar_int{
		padding: .58rem 0;
		margin-top: .19rem;
		background-color: $colorTerciary;
		border: solid 1px $colorTerciary;
		color: white;
		font-size: .9rem;
		font-weight: 600;
		cursor: pointer;
		&:hover{
			background-color: darken($colorTerciary, 5);
		}
	}
	.page-wrap{
		display: block;
	}
	.page-wrap .width-50, .main-content, .main-content2{
		width: 100%;
	}
	#ux_to_top{
		left: -120%;
	}

	.bganuncie{
		background-color: $gray5;
		height: 25rem;
		filter: brightness(100%);
		position: relative;
		border-right: solid 1px transparent;
		&:hover{
			filter: brightness(103%);
		}
	}
	
	.bgencomende{
		background-color: $gray5;
		filter: brightness(100%);
		height: 25rem;
		position: relative;
		&:hover{
			filter: brightness(103%);
		}
	}
	
	.textanuncie{
		position: absolute;
		left: 50%;
		top: 40%;
		transform: translate(-50%, -50%);
		text-align: center !important;
		& h3{
			color: $colorSecondary;
			font-size: 2.2rem;
			font-weight: 600
		}
	
		& p{
			font-size: 1.2rem;
			color: $colorSecondary;
			line-height: 1.5em;
			font-weight: 500;
			width: 20rem;
			text-align: center !important;
		}
		.buttonanuncie{
			border-radius: .2rem;
			text-transform: initial !important;
			font-size: 1.1rem;
			font-weight: 800
		}
	}
}