/* UX BANNER */
.ux-banner{
	display: block;
	position: relative;
	z-index: 0;
	width: 100%;
	padding: 0px;
	background-color: #EEE;
	outline: none;
	overflow: hidden;
	min-height: 80px;
}
.ux-banner-canvas{
	position: relative;
	display: block;
	text-align: center;
	padding: 0px;
	margin: 0px;
	width: inherit;
	height: inherit;
	max-width: inherit;
	max-height: inherit;
	overflow: hidden;
}
.ux-banner-canvas-hidden div{
	display: none !important;
}
.ux-banner-canvas div{
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: inherit;
	height: inherit;
	max-width: inherit;
	max-height: inherit;
	padding: 0px;
	margin: 0px;
	z-index: 4;
}
.ux-banner-canvas a{
	display: block;
	width: 100%;
	height: 100%;
	max-width: inherit;
	max-height: inherit;
	padding: 0px;
	margin: 0px;
}
.ux-banner-canvas img{
	width: auto !important;
	height: 100%;
	max-width: inherit;
	max-height: inherit;
	padding: 0px;
	margin: 0px;
	box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
}
.ux-banner-nav-div{
	display: block;
	margin: auto;
	width: 100%;
	max-width: 1280px;
}
.ux-banner-left, .ux-banner-right{
	cursor: pointer;
	position: absolute;
	z-index: 5;
	border: none;
	outline: none;
	border-radius: 18px;
	line-height: 36px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-size: 14px;
	color: $colorWhite;
	background-color: rgba(0,0,0,0.2);

	&:hover, &:active, &:focus{
		color: $colorPrimary;
		background-color: $colorWhite;
		color: $gray3;
	}
}
.ux-banner-left{
	left: 20px;
	bottom: 10px;
}
.ux-banner-right{
	right: 20px;
	bottom: 10px;
}
.ux-banner-legenda{
	position: absolute;
	z-index: 4;
	width: calc(100vw - 240px);
	left: 50%;
	transform: translateX(-50%);
	bottom: 10px;
	text-align: center;
	color: white;
	font-weight: 400;
	font-size: $tamanhoFonte;
	line-height: 36px;
	text-shadow: 0px 0px 1px #FFF;
}

@media all and (max-width: 1024px){
	.ux-banner-legenda{
		font-size: 1rem;
	}
}
.ux-banner-left:hover,
.ux-banner-right:hover,
.ux-banner-slide:hover,
.ux-banner-slide-pause:hover{
	opacity: 1;
}
.ux-banner-slide{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 10px;
	background-image: url(/jnh/icones/ux-banner.png);
	background-repeat: no-repeat;
	background-size: 40px 120px;
    background-position: 0px 0px;
	opacity: 0.3;
}
.ux-banner-slide-pause{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 10px;
	background-image: url(/jnh/icones/ux-banner.png);
	background-repeat: no-repeat;
	background-size: 40px 120px;
    background-position: -20px 0px;
	opacity: 1;
}



/* ANIMAÇÕES */
.vir-da-direita {
	animation: alternate virdadireita 0.5s ease-in-out;
	animation-fill-mode: forwards;
}
@keyframes virdadireita {
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}
.sair-direita {
	animation: alternate sairdireita 1s ease-in;
	animation-fill-mode: forwards;
}
@keyframes sairdireita {
	40% {
		opacity: 0.2;
	}
	100% {
		transform: translateX(100%);
		opacity: 0.2;
	}
}

.vir-da-esquerda {
	animation: alternate virdaesquerda 0.5s ease-in-out;
	animation-fill-mode: forwards;
}
@keyframes virdaesquerda {
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}
.sair-esquerda {
	animation: alternate sairesquerda 1s ease-in;
	animation-fill-mode: forwards;
}
@keyframes sairesquerda {
	40% {
		opacity: 0.2;
	}
	100% {
		transform: translateX(-100%);
		opacity: 0.2;
	}
}