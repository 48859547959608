/* MINIATURA 1 */
.miniatura-imv-status{
	bottom: 10px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorPrimary;
	padding: 5px 10px;
	color: $colorTerciary;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.miniatura-imv-mobiliado{
	bottom: 44px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorSecondary;
	padding: 5px 10px;
	color: #ffffff;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.imoveldestaque{
	top: 46px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorSecondary;
	padding: 5px 10px;
	color: $gray1;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.linkg{
	&:hover{
		color: $colorPrimary !important;
	}
}
.pd-05{
	padding-top: 0.3rem;
}

.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	
	
	& .miniatura-imovel-img{
		box-shadow: 0 0 20px $gray3;
		border-radius: .3rem .3rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1;
		position: relative;
		user-select: none;
		& img{
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: -30%;
			transition: all .5s ease;
			&:hover{
				transform: scale(1.1);
			}
		}
		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video{
		@extend .miniatura-imovel-img;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: 50%;
			object-fit: none;
			object-position: initial !important;
			transform: translate(0,-50%);
			
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;
		
		& .left {
			color: darken($gray3, 7);
			font-size: 1.2rem;
		}
		& .right {
			color: darken($gray3, 7);
			font-size: 1rem;
			letter-spacing: -.05rem;
    		font-weight: 300;
		}
	}

	& .miniatura-cidade-estado{
		display: inline-block;
		width: 100%;
		height: 1.5rem;
		line-height: 1.3rem;
		overflow: hpd-05idden;
		vertical-align: middle;
		text-transform: uppercase;
		margin-bottom: 1rem;
		letter-spacing: .05rem;
		font-size: 1rem;
		font-weight: 700;
		color: $colorSecondary;
	}
	& .miniatura-acao-tipo{
		font-size: 1rem;
		font-weight: 600;
		color: $gray2;
		display: inline-block;
		width: 75%;
	}

	& .miniatura-cod{
		font-size: 1rem;
		font-weight: 600;
		color: white;
		text-align: center;
		display: inline-block;
		width: 25%;
		background-color: $gray3;
		border-radius: .2rem;
		padding: .2rem 0;
	}

	& .miniatura-container-id{
		display: inline-block;
		width: 5rem;
		vertical-align: middle;
		text-align: right;
	}

	& .miniatura-imovel-endereco {
		font-style: oblique;
	}

	& .miniatura-imovel-preco {
		padding: .5rem;
		@include border-box;
		background-color: transparent;
		font-size: 1.2rem;
		color: $colorSecondary;
	}

	& .miniatura-p {
		text-align: center;
		border-radius: 0 .3rem .3rem 0;
		padding: .5rem .3rem;
		color: white;
		font-weight: 600;
		font-size: 1.1rem;
		background-color: $colorPrimary;
		&:hover{
			color: white;
			background-color: darken($colorPrimary, 5);
			transition: .2s all;
		}
	}

	& .miniatura-imovel-detalhe{
		box-shadow: 0 15px 25px $gray4;
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;

		& .btn_fav_bg{
			background-color: white;
			border: solid 1px $gray4;
			height:2.4rem;
			border-radius: .3rem 0 0 .3rem;
			position: relative;

			button {
				padding-top: .1rem;
			}
		}


		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 3rem;
			border-top: solid 1px rgba($gray4, .5);
			border-bottom: solid 1px rgba($gray4, .5);
			padding: .5rem 0;
			vertical-align: middle;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}
}

.cr {
	width: 9rem;
	padding: 0.3rem;
	position: absolute;
	text-align: center;
	color: #f0f0f0;
  }
.cr-sticky {
	position: absolute;
  }
  
/* Positions */
.cr-top    { top: 1.5rem; }
.cr-bottom { bottom: 1.5rem; }
.cr-left   { left: -3rem; }
.cr-right  { right: -3rem; }
  
/* Rotations */
.cr-top.cr-left, .cr-bottom.cr-right {
	transform: rotate(-45deg);
}
.cr-top.cr-right, .cr-bottom.cr-left {
	transform: rotate(45deg);
}

/* Colors */
.cr-primary{ 
	background-color: $colorPrimary;
	font-weight: 600; 
	color:white;
}


/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}
	
}