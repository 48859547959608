.nav {
	display: block;
	width: 50vh;
	position: fixed;
	top: 0;
	right: -1000px;
	background-image:linear-gradient(to bottom right,  rgba($colorPrimary, .95), rgba($colorPrimary, .8));
	z-index: 99991;
	height: 100vh;
	overflow: auto;

	& button {
		margin-right: 1rem;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
	}

	& .nav-titulo {
		color: $colorTerciary;
		font-size: 1.7rem;
		font-weight: 600;
		padding: 1.5rem 1rem;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: $colorTerciary;
		font-size: 1.4rem;
		font-weight: 400;
		margin: 0;
		padding: .6rem 1rem;
		@include border-box;
		transition: all .2s;
		border-left: solid 3px transparent;
		&:hover {
			padding-left: 2rem;
			border-left: solid 3px $colorSecondary;
		}
	}
}

@media all and (max-width: 1023px){
	.nav{
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}