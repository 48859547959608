.header1{
	//background-image: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	position: fixed !important;
	width: 100%;
}
.header2{
	background-color: $colorTerciary;
	width: 100%;
}
.header3{
	width: 100%;
	animation: header3pc .45s ease-in-out;
	animation-fill-mode: forwards;
	transform-origin: center center;
}

@keyframes header3pc{
	0% {
		//background-image: linear-gradient(to bottom right, #00174900, #03428200);
		background-color: transparent;
	}
	100% {
		//background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
		background-color: $colorPrimary;
	}
}
.headerindex {
	background-color: white;
	display: block;
	padding: 0;
	height: 35vh;
	@include border-box;
	z-index: 2;
}
.headerinterno {
	height: 5rem;
	z-index: 1;
}
.busca-top{
	width: 100%;
	background-color: $colorPrimary;
	height: 2rem;
}

.btn-home{
	height: 39px !important;
}

#sub_menu_vendas{
	display: none;
	position: absolute;
	left: 13.3rem;
	top: 8.7rem;
	background-color: white;
	border-radius: 0 0 .5rem .5rem;
	box-shadow: 7px 12px 15px rgba(black, .3);
	margin: 0;
	width: 50%;

	& .subsubmenu{
		background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorPrimary, .7));
		color: $colorTerciary;
		font-size: 1rem;
		font-weight: 600;
		padding: 1rem 0;
		border-radius: 0 0 .5rem .5rem;
		width: 100%;
		& p{
			color: white;
			font-weight: 400;
		}
		& a, & a:visited{
			display: block;
			font-size: 0.9rem;
			font-weight: 400;
			padding: 0.20rem 1rem;
			text-align: left !important;
			width: 100%;
			@include border-box;
			border-left: solid 3px transparent;
			transition: all .2s;
		}
		& a:hover{
			padding-left: 2rem;
			border-left: solid 3px $colorSecondary;
			& p {
				font-weight: 600;
				color: #FFF;
			}
		}
	}
}

#sub_menu_vendas2{
	display: none;
	position: absolute;
	right: 0;
	top: 8.7rem;
	background-color: white;
	border-radius: 0 0 .5rem .5rem;
	box-shadow: 7px 12px 15px rgba(black, .3);
	margin: 0;
	width: 50%;

	& .subsubmenu{
		background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorPrimary, .7));
		color: $colorTerciary;
		font-size: 1rem;
		font-weight: 600;
		padding: 1rem 0;
		border-radius: 0 0 .5rem .5rem;
		& p{
			color: white;
			font-weight: 400;
		}
		& a, & a:visited{
			display: block;
			font-size: 0.9rem;
			font-weight: 400;
			padding: 0.20rem 1rem;
			text-align: left !important;
			width: 100%;
			@include border-box;
			border-left: solid 3px transparent;
			transition: all .2s;
		}
		& a:hover{
			padding-left: 2rem;
			border-left: solid 3px $colorSecondary;
			& p {
				font-weight: 600;
				color: #FFF;
			}

		}
	}
}

#sub_menu_vendas3{
	display: none;
	position: absolute;
	left: 0;
	top: 8.7rem;
	background-color: white;
	border-radius: 0 0 .5rem .5rem;
	box-shadow: 7px 12px 15px rgba(black, .3);
	margin: 0;
	width: 50%;

	& .subsubmenu{
		background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorPrimary, .7));
		color: $colorTerciary;
		font-size: 1rem;
		font-weight: 600;
		padding: 1rem 0;
		border-radius: 0 0 .5rem .5rem;
		& p{
			color: white;
			font-weight: 400;
		}
		& a, & a:visited{
			display: block;
			font-size: 0.9rem;
			font-weight: 400;
			padding: 0.20rem 1rem;
			text-align: left !important;
			width: 100%;
			@include border-box;
			border-left: solid 3px transparent;
			transition: all .2s;
		}
		& a:hover{
			padding-left: 2rem;
			border-left: solid 3px $colorSecondary;
			& p {
				font-weight: 600;
				color: #FFF;
			}

		}
	}
}


.headerpc {
	box-shadow: 5px 7px 20px rgba(black, .1);
	display: block;
	width: 100%;
	position: relative;
	
	& .superior {
		display: block;
		width: 100%;
		color: #ffffff;
		z-index: 3;
		
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 11.8rem;
			padding: 1rem 0;
			& img {
				display: block;
				width: 100%;
			}
		}

		/* GRUPO HEADER */

		$largura_share: 7;
		$largura_menu: 3;
		$largura_creci: 10;
		$largura_contact: 13;
		$diferenca: 14;
		& .share {
				display: inline-block;
				vertical-align: middle;
				width: #{$largura_share}rem;
				text-align: center;
			& .item{
				display: inline-block;
				vertical-align: middle;
				padding: 0.5rem;
				width: 1.5rem;
				& img{
					width: 100%;
				}
			}
		}

		& .creci {
			text-align: center;
			display: inline-block;
			width: #{$largura_creci}rem;
			vertical-align: middle;
			color: $gray1;
			font-size: 0.9rem;
			font-weight: 400;
		}
		& .menu {
			text-align: right;
			display: inline-block;
			width: #{$largura_menu}rem;
			vertical-align: middle;
		}

		& .sessions {
			display: inline-block;
			width: calc(100% - #{(($largura_share + $largura_creci + $largura_menu + $largura_contact) + $diferenca)}rem);
			vertical-align: middle;
			position: relative;

			& .itemsession{
				display: inline-block;
				vertical-align: middle;
				width: 33.3%;
				
				&:hover{
					#sub_menu_vendas, #sub_menu_vendas2, #sub_menu_vendas3 {
						display: initial;
					}
				}
				& .a{
					display: block;
					width: 100%;
					font-size: 1rem;
					color: $colorTerciary;
					line-height: 2.3rem;
					font-weight: 600;
					padding: 0 0.9rem;
					@include border-box;

					&:hover{
						font-weight: 700;
					}
				}
				
				& i{
					padding-left: 0.4rem;
				}

				&:hover .sub{
					display: block;
				}

				&:hover .bg{
					background-color: $colorSecondary;
					color: white !important;
					height: 8.7rem;
					line-height: 8.7rem !important;
				}

				&:hover .a{
					font-weight: 600;
				}


				& .sub{
					display: none;
					width: 18%;
					position: absolute;
					text-align: left;
					z-index: 9999;
					overflow: auto;
					max-height: calc(100vh - 10rem);

					& .link{
						display: block;
						width: 65%;
						background-color: white;
						color: $gray2 !important;
						padding: 0.1rem 0.8rem;
						font-size: 1rem !important;
						line-height: 2.3rem;
						transition: .15s all;
						@include border-box;

						& i{
							color: $gray1;
						}

						&:hover{
							background-color: $colorPrimary;
							color: white !important;
							font-weight: 900;
							padding-left: 1rem;
						}

						&:hover i{
							color: white;
						}
					}
				}
			}
		}
		& .contact{
			display: inline-block;
			width: #{$largura_contact}rem;
			vertical-align: middle;
			text-align: center;
			& .mg-tx{
				margin-bottom: 1rem !important;
				margin-top: 0.1rem !important;
			}
			& .mg-tx2{
				margin-bottom: 0 !important;
				margin-top: 0 !important;
			}
		}

	}

	& .busca {
		display: block;
		position: absolute;
		width: 45rem;
		bottom: 50%;
		left: 50%;
		text-align: center;
		transform: translate(-50%, 50%);
		& h2 {
			color: #ffffff;
			font-size: 2rem;
		}
	}

	& .frase {
		position: absolute;
		width: 50%;
		right: 5%;
		top: 32%;
		text-align: left;

		& span{
			line-height: 3rem !important;
			font-size: 2.3rem;
		}
		& h2 {
			color: #ffffff;
			font-size: 2rem;
		}
	}
}

.header-frases-arte{
	font-family: 'Quick Signature';
	color: lighten(white, 10%);
	text-shadow: 7px 2px 4px rgba(0, 0, 0, 1);
	font-size: 10rem;
	font-weight: 300;
	line-height: 30px;
}


.videoArea {
	z-index: 1;
	display: block;
	position: relative;
	width: 100%;
	height: 86vh;
	background-color: $colorPrimary;
	background-image: url('/jnh/header/bg-header-01.jpg');
	background-size: cover;

/* 	& .videoareaareaoverlay {
		display: block;
		z-index: 3;
		background-color:rgba(black,0.5);
		position: absolute;
		width: 100%;
		height: 100%;
	}
	& .videoareaarea {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 1;
		height: 100%;
		pointer-events: none;
		overflow: hidden;

		& video {
			object-fit: cover;
			width: 100vw;
			z-index: 1;
			height: 100vh;
			display: inline-block;
			vertical-align: baseline;
		}
	} */

	& .container-header-pc{
		width: 100%;
		height: 100%;
	}
}
.container-bg-headerpc{
	display: block;
	position: absolute;
	top:0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.bg-headerpc{
	display: block;
	position: absolute;
	bottom: 0;
	height: 100vh;
	min-height: 40rem;
	width: 100%;
	will-change: background-image;
	background-image: url('/jnh/header/header.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	animation-name: bgheaderpc;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal;
}

.header-mob{
	background-color: $colorSecondary;
	& .log{
		display: inline-block;
		vertical-align: middle;
		& img{
			width: 10rem;
		}
	}
	& .btn-men{
		display: inline-block;
		vertical-align: middle;
	}
}
// @include keyframes('bgheaderpc'){
// 	0% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	30% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	40% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-02.jpg');
// 	}
// 	60% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-02.jpg');
// 	}
// 	70% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	100% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// }

.bg-headerinterno {
	background-color: darken($colorPrimary, 5);
}

.headermobile-btn{
	position: absolute;
	width: 100%;
	max-width: 280px;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);
}
.container-bg-headermobile{
	display: block;
	position: absolute;
	top: 0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.headermobile {
	display: block;
	width: 100%;
	height: calc(100vh - 65px);
	min-height: calc(100vh - 65px);
	position: relative;
	background-color: $colorPrimary !important;
	will-change: background-image;
	background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center top;
	animation-name: headermobile;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal;
}
// @include keyframes('headermobile'){
// 	0% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	30% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	40% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
// 	}
// 	60% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
// 	}
// 	70% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	100% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// }

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (max-width: 1490px){
	.videoArea{
		background-position: 40%;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	#sub_menu_vendas{
		left: 12.1rem;
	}
}

@media all and (max-width: 1023px){
	.headerindex{
		height: 28rem;
	}
	.headerpc{
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
		& .busca{
			display: block;
			position: absolute;
			width: initial;
			top: initial;
			right: initial;
			transform: initial;
			left: 50%;
			width: 80%;
			max-width: 37rem;
			transform: translate(-50%);
			bottom: 5%;
				
			& h2{
				font-size: 1.4rem !important;
				font-weight: 800 !important;
			}
			& .btn-home{
				width: 55% !important;
			}
			& .formsimple-input-bc{
				width: 30%;
			}
		}
	}
	.videoArea{
		display: block;
		width: 100%;
		height: calc(52rem);
		min-height: calc(52rem);
		position: relative;
		/* background-color: #04111A !important; */
		background-color: $colorPrimary !important;
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
		& .frase{
			position: absolute;
			top: 15%;
			width: 50%;
			right: 4%;
			text-align: left;
			& span{
				line-height: 3rem !important;
				font-size: 2.1rem !important;
			}
		}
		& .container-header-pc{
			display: block;
			width: 100%;
			position: initial;
			background-color: initial !important;
			top: initial;
			left: initial;
			transform: initial;
			z-index: 10;
			max-width: 65rem;
		}
	}
	
}

@media all and (max-width: 600px) and (min-width: 480px){
	.videoArea{
		display: block;
		width: 100%;
		height: calc(54rem) !important;
		min-height: calc(54rem) !important;
		position: relative;
		/* background-color: #04111A !important; */
		background-color: $colorPrimary !important;
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
		
	}
}
@media all and (max-width: 1204px){
	.headerpc{
	
		& .superior{
			& .sessions{
				& .itemsession{
					& a{
						line-height: 1.2rem !important;
					}
				}
			}
		}

	}
}

@media all and (min-width: 480px){
	.videoArea{
		background-image: url('/jnh/header/bg-header-01.jpg');
		
	}
}

@media all and (max-width: 480px){
	.videoArea{
		height: calc(50rem);
		min-height: calc(50rem);
		& .frase{
			position: absolute;
			top: 11%;
			width: 30%;
			right: 6%;
			& span{
				line-height: 2.5rem !important;
				font-size: 1.1rem !important;
			}
		}
	}
	.headerindex{
		height: 24rem;
		margin-bottom: 0;
	}
}
@media all and (max-width: 360px){
	.videoArea{
		height: calc(52rem);
		min-height: calc(52rem);
	}
	.headerindex{
		height: 30rem;
		margin-bottom: 1rem;
	}
}