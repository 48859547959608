.imovel-ver-preco {
	vertical-align: middle;

	padding: 1rem;
	margin: 1rem 0;
	@include border-box;
	background-color: $colorPrimary;
	font-size: 1.3rem;
	text-align: center;
}
.imovel-ver-disponivel {
	vertical-align: middle;

	padding: 1rem;
	margin: 1rem 0;
	@include border-box;
	background-color: $colorPrimary;
	font-size: 1.3rem;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
}

.box_preco{
	background-color: $colorPrimary;
	padding: .5rem 1rem;
	width: 65%;
	text-align: center;
	margin: 0;
	font-weight: 400;
	font-size: 1.7rem;
}

/*
	01 Sala, 01 Cozinha, 03 Garagens, etc..
 */
.imovel-ver-informacoes{
	& ul li {
		color: $gray1;
		margin: .5rem 0;

		& i {
			padding-right: .4rem;
		}
	}
}
@media all and (max-width: 1023px){
	.imovel-ver-preco {
		margin-bottom:0 !important;
	}
	.imovel-ver-disponivel {
		margin-top:0 !important;
	}
}