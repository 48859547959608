.busca-filtros{
	display: block;
	width: 100%;
	background-color: white;
	padding: 1rem;
	border: 1px solid $gray1;
	@include border-box;

	& h2 {
		text-align: center;
		border-bottom: 1px solid $gray1;
	}
}


// SETA DA PAGINAÇAO

@-webkit-keyframes uparrow {
	0% { -webkit-transform: translateY(0); opacity: 0.4 }
	100% { -webkit-transform: translateY(-0.4em); opacity: 0.9 }
  }
@-webkit-keyframes downarrow {
0% { -webkit-transform: translateY(0); opacity: 0.4 }
100% { -webkit-transform: translateY(0.4em); opacity: 0.9 }
}

.arrow {
	border-color:transparent;
	border-style:solid;
	border-width:0 2em;
	display:block;
	height:0;
	margin-top: 2rem;
	opacity:0.4;
	text-indent:-9999px;
	transform-origin: 50% 50%;
	width:0;
}
.up {
	-webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
	border-bottom:2em solid $colorPrimary;
}
.down {
	-webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
	border-top:2em solid $colorPrimary;
}


.height-but{
	height: 3rem !important;
}

.bg-person{
	background-color: $colorPrimary;
	border-top: solid 0.5px white;
}
@media all and (max-width: 1023px){
	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		padding-bottom: 7rem;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.busca-filtrosssss{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;

		& .busca-filtros {
			padding-top: 4rem;
			padding-bottom: 7rem;
			border: 0 solid $gray1;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
}
.heigt{
	height: 2.3rem !important;
}
.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}
